.adopciones-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.slider-section {
  width: 100%;
}

.form-section {
  width: 100%;
  padding: 20px;
}

.slider-column, .form-column {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.slider-text {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
  color: white;
  z-index: 2;
  padding: 0 20px;
}

.slider-text h1 {
  font-size: 3.7em;
  font-weight: bold;
}

.slider-text h1 .highlight {
  color: red;
}

.slider-text h2 {
  font-size: 2.5em;
  font-style: italic;
}

.slider-column .image {
  opacity: 2;
}

.slider-column {
  overflow: hidden;
}

@media (max-width: 1024px) {
  .slider-text h1 {
    font-size: 3.2em;
  }

  .slider-text h2 {
    font-size: 2em;
  }

  .form-section {
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .slider-text h1 {
    font-size: 2.5em;
  }

  .slider-text h2 {
    font-size: 1.8em;
  }
}

@media (max-width: 480px) {
  .slider-column {
    height: 30vh;
  }

  .slider-text {
    top: 25%;
  }

  .slider-text h1 {
    font-size: 2em;
  }

  .slider-text h2 {
    font-size: 1.5em;
  }
}
