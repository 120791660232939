.main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  font-family: 'Times New Roman', Times, serif;
}

.slider-container {
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  position: relative;
}

.slider {
  display: flex;
  width: 100%;
  height: auto;
  transition: transform 0.5s ease;
}

.slide {
  width: 100%;
  display: none;
  position: relative;
  opacity: 0.9;
  transition: opacity 1s ease;
}

.slide.active {
  display: block;
  opacity: 0.9;
}

.image {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.slide-content {
  position: absolute;
  top: 10%;
  left: 5%;
  text-align: left;
  color: white;
  z-index: 1;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.2);
}

.title {
  font-family: 'Times New Roman', Times, serif;
  font-size: 3vw;
  font-weight: bold;
  margin-bottom: 10px;
  color: rgb(255, 221, 0);
}

.title2 {
  font-family: 'Times New Roman', Times, serif;
  font-size: 2vw;
  font-weight: bold;
  margin-bottom: 10px;
  color: rgb(255, 255, 255);
}

.subtitle {
  font-family: 'Times New Roman', Times, serif;
  font-size: 1.5vw;
  font-weight: bold;
  font-style: italic;
}

.adopt-button,
.support-button,
.donate-button {
  background-color: rgb(255, 221, 0);
  color: rgb(4, 4, 4);
  font-size: 1.5vw;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

.dots-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 5px;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: rgba(255, 255, 255, 1);
}

.info-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  background-color: white;
  color: black;
}

.info-text {
  flex: 1;
  padding: 20px;
  font-size: 2vw;
  font-family: 'Times New Roman', Times, serif;
}

.info-text p {
  margin: 20px 0;
}

.info-image {
  flex: 1;
  padding: 20px;
  text-align: center;
}

.info-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

@media (max-width: 1024px) {
  .title {
    font-size: 4vw;
  }

  .title2 {
    font-size: 3vw;
  }

  .subtitle {
    font-size: 2.5vw;
  }

  .adopt-button,
  .support-button,
  .donate-button {
    font-size: 2vw;
  }

  .info-section {
    flex-direction: column;
    height: auto;
    padding: 10px 0;
  }

  .info-text {
    font-size: 4vw;
    padding: 10px;
  }

  .info-text p {
    margin: 10px 0;
  }

  .info-image {
    padding: 10px;
  }

  .info-image img {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 6vw;
  }

  .title2 {
    font-size: 5vw;
  }

  .subtitle {
    font-size: 4vw;
  }

  .adopt-button,
  .support-button,
  .donate-button {
    font-size: 3vw;
  }

  .info-text {
    font-size: 5vw;
  }
}

.support-section {
  background-image: url('../img/i9.jpeg'); /* Ruta de la imagen de fondo */
  background-size: cover; /* Cubre toda la sección */
  background-position: center; /* Centra la imagen */
  color: white; /* Color del texto */
  width: 100%;
  height: 100vh; /* Ajusta la altura para cubrir toda la vista */
  padding: 50px 0; /* Espacio superior e inferior */
  text-align: center; /* Centra el texto */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Alinea el contenido hacia arriba */
  margin: 0; /* Eliminar margen adicional */
}

.support-section h2 {
  font-size: 3vw; /* Tamaño del título principal */
  font-weight: bold; /* Negrilla */
  margin: 0; /* Eliminar espacio debajo del título */
}

.support-section h3 {
  font-size: 2.5vw; /* Tamaño del título secundario */
  color: rgb(255, 242, 0); /* Color anaranjado */
  margin: 10px 0; /* Espacio debajo del título secundario */
}

.columns {
  display: flex; /* Utiliza flexbox para las columnas */
  justify-content: space-around; /* Distribuye las columnas equitativamente */
  flex-wrap: wrap; /* Permite que las columnas se ajusten en varias filas si es necesario */
  margin-top: 20px; /* Espacio superior para las columnas */
}

.column {
  flex: 1; /* Cada columna ocupa el mismo espacio */
  margin: 10px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
  border-radius: 10px; /* Bordes redondeados */
  min-width: 200px; /* Ancho mínimo para las columnas */
}

.column p {
  margin: 5px 0; /* Reducir espacio en los párrafos */
}

.column .number {
  font-size: 2.5vw; /* Tamaño de la letra para los números */
  font-weight: bold; /* Negrilla */
  margin-bottom: 5px; /* Reducir espacio debajo del número */
}

@media (max-width: 1024px) {
  .support-section {
    padding: 30px 0;
  }

  .support-section h2 {
    font-size: 5vw;
  }

  .support-section h3 {
    font-size: 4vw;
  }

  .column .number {
    font-size: 4vw;
  }

  .column {
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .support-section {
    padding: 20px 0;
  }

  .support-section h2 {
    font-size: 6vw;
  }

  .support-section h3 {
    font-size: 5vw;
  }

  .column .number {
    font-size: 5vw;
  }

  .column {
    padding: 5px;
  }
}
