.contactanos-container {
  width: 100%;
  padding: 0; /* Elimina rellenos */
  box-sizing: border-box;
  margin: 0;
}

.contact-header {
  background-image: url('../img/p2.jpeg');
  background-size: cover;
  background-position: center; /* Alinea la imagen de fondo hacia el centro */
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  color: white;
  display: flex;
  align-items: center;
  margin: 0; /* Elimina márgenes */
  font-family: 'Times New Roman', Times, serif;
}

.header-content {
  display: flex;
  width: 100%;
  margin: 0; /* Elimina márgenes */
  padding: 0; /* Elimina rellenos */
}

.left-column {
  flex: 1;
  padding: 20px;
}

.right-column {
  flex: 1;
}

.header-title-orange {
  font-size: 2em;
  font-weight: bold;
  color: darkorange;
  font-family: 'Times New Roman', Times, serif;
}

.header-title-green {
  font-size: 2em;
  font-weight: bold;
  color: rgba(7, 219, 239, 0.919);
  font-family: 'Times New Roman', Times, serif;
}

.contact-form-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 40px;
  font-family: 'Times New Roman', Times, serif;
}

.contact-form-column,
.contact-info-column {
  flex: 1;
  padding: 20px;
}

.contact-form-column h2,
.contact-info-column h2 {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.contact-form-column .form-group {
  margin-bottom: 15px;
}

.contact-form-column .form-group label {
  display: block;
  font-size: 1.2em;
  margin-bottom: 5px;
}

.contact-form-column .form-group input,
.contact-form-column .form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  box-sizing: border-box;
}

.contact-form-column button {
  background-color: #87CEEB;
  color: white;
  font-size: 1.2em;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.contact-form-column button:hover {
  background-color: #0099cc;
}

.contact-info-column {
  font-family: 'Times New Roman', Times, serif;
  font-size: 1.2em;
  text-align: center;
}

.contact-info-column p {
  margin-bottom: 10px;
  font-family: 'Times New Roman', Times, serif;
}

.social-media {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.social-media-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.social-media-item img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.contact-map {
  margin-top: 10px;
}

.contact-map h2 {
  font-size: 1.5em;
  margin-bottom: 20px;
  font-family: 'Times New Roman', Times, serif;
}

.contact-map iframe {
  width: 100%;
  height: 450px;
  border: 0;
  padding: 0.5cm, 0;
}

@media (max-width: 1024px) {
  .contact-header {
    background-image: url('../img/p2.jpeg'); /* Cambia la imagen de fondo para dispositivos móviles */
    background-size: cover;
    background-position: center top; /* Ajusta la posición de la imagen de fondo */
    height: auto; /* Ajusta la altura según el contenido */
    margin: 0; /* Elimina márgenes */
    padding: 0; /* Elimina rellenos */
    width: 100vw; /* Asegura que el contenedor ocupe todo el ancho de la ventana gráfica */
    color: rgb(255, 255, 255);
    font-weight: bold;
    align-items: center;
  }

  .header-title-orange {
    font-size: 2em;
    margin-top: 2cm;
    font-weight: bold, italic;
    color: rgb(255, 255, 255);
  }
  
  .header-title-green {
    font-size: 2em;
    font-weight: bold;
    color:  #ff0000;
  }

  .contact-form-section {
    flex-direction: column;
    padding: 0; /* Elimina rellenos */
    margin: 0; /* Elimina márgenes */
  }

  .contact-form-column,
  .contact-info-column {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
  }

  .header-content {
    flex-direction: column;
    margin: 0; /* Elimina márgenes */
    padding: 0; /* Elimina rellenos */
  }

  .left-column,
  .right-column {
    width: 100%;
    text-align: center;
    padding: 10px;
  }

  .contact-form-column .form-group label,
  .contact-form-column .form-group input,
  .contact-form-column .form-group textarea {
    font-size: 1em;
  }

  .contact-map iframe {
    width: 100%; /* Asegura que el iframe ocupe todo el ancho disponible */
    height: 300px; /* Ajusta la altura para pantallas pequeñas */
  }
}
