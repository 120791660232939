.slider-container-adopciones {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  font-family: 'Times New Roman', Times, serif;
}

.slider {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 1.2cm;
}

.slider-adopciones {
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.slider-adopciones.active {
  display: block;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
 
}

.slider-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
  color: white;
  z-index: 1;
}

.slider-text h1 {
  font-size: 3.7em;
  font-weight: bold;
}

.slider-text h1 .highlight {
  color: red;
}

.slider-text h2 {
  font-size: 2.5em;
  font-style: italic;
  font-family: 'Times New Roman', Times, serif;
}

.dots-container-adopciones {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 5px;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: rgba(255, 255, 255, 1);
}

@media (max-width: 1024px) {
  .slider-container-adopciones {
    height: 50vh;
  }

  .slider {
    height: 50vh;
  }

  .slider-adopciones {
    height: 50vh;
  }

  .slider-text {
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    font-family: 'Times New Roman', Times, serif;
    font-size: x-small ;
    font-style: bold;
    
  }

  .slider-text h1 {
    font-size: 1em;
  }

  .slider-text h2 {
    font-size: 1em;
  }
}

@media (max-width: 768px) {
  .slider-container-adopciones {
    height: 40vh;
  }

  .slider {
    height: 40vh;
  }

  .slider-adopciones {
    height: 40vh;
  }

  .slider-text {
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
  }

  .slider-text h1 {
    font-size: 2.5em;
  }

  .slider-text h2 {
    font-size: 1.8em;
  }
}

@media (max-width: 480px) {
  .slider-container-adopciones {
    height: 30vh;
  }

  .slider {
    height: 30vh;
  }

  .slider-adopciones {
    height: 30vh;
  }

  .slider-text {
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
  }

  .slider-text h1 {
    font-size: 2em;
  }

  .slider-text h2 {
    font-size: 1.5em;
  }
}
