.nosotros {
  padding: 3px;
  text-align: center;
  margin-top: 75px; /* Ajusta este valor según la altura de tu header */
  font-family: 'Times New Roman', Times, serif;
}

.nosotros h1 {
  font-size: 2.5em; /* Aumenta el tamaño del título */
  margin-bottom: 20px;
  font-family: 'Times New Roman', Times, serif;
}

.section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; /* Ancho de cada sección */
  margin: 0 auto 10px auto; /* Centra la sección horizontalmente y agrega espacio inferior */
  padding: 5px;
  background-color: #ffffff; /* Color de fondo blanco */
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap; /* Asegura que los elementos se ajusten en dispositivos pequeños */
  font-family: 'Times New Roman', Times, serif;
}

.section .text {
  flex: 1;
  padding: 20px;
  text-align: left; /* Alineación de texto al centro */
  font-size: 1.5em; /* Tamaño del texto */
  animation: slideInBottom 1.5s ease-in-out; /* Aplica animación al texto */
  font-family: 'Times New Roman', Times, serif;
}

.section .image {
  flex: 1;
  display: flex;
  justify-content: center;
  animation: slideInRight 1.5s ease-in-out; /* Aplica animación a la imagen */
}

.section .image img {
  width: 70%;
  height: auto;
  border-radius: 10px;
}

.section h2 {
  font-size: 1.8em; /* Aumenta el tamaño del subtítulo */
  margin-bottom: 20px;
  font-family: 'Times New Roman', Times, serif;
}

.section p {
  font-size: 1.2em;
  line-height: 1.6;
  font-family: 'Times New Roman', Times, serif;
}

/* Animaciones */
@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInBottom {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-in-right {
  animation: slideInRight 1.5s ease-in-out;
}

.slide-in-bottom {
  animation: slideInBottom 1.5s ease-in-out;
}

/* Media Queries para Responsividad */
@media (max-width: 1024px) {
  .nosotros h1 {
    font-size: 2em; /* Ajusta el tamaño del título para pantallas medianas */
  }

  .section .text {
    font-size: 1.2em; /* Ajusta el tamaño del texto para pantallas medianas */
  }

  .section h2 {
    font-size: 1.5em; /* Ajusta el tamaño del subtítulo para pantallas medianas */
  }

  .section p {
    font-size: 1em; /* Ajusta el tamaño del párrafo para pantallas medianas */
  }

  .section .image img {
    width: 80%; /* Ajusta el tamaño de la imagen para pantallas medianas */
  }
}

@media (max-width: 768px) {
  .nosotros h1 {
    font-size: 1.8em; /* Ajusta el tamaño del título para pantallas pequeñas */
  }

  .section {
    flex-direction: column; /* Coloca texto e imagen en columnas para pantallas pequeñas */
    text-align: center; /* Centra el texto */
  }

  .section .text,
  .section .image {
    order: 0; /* Restablece el orden predeterminado */
    width: 100%; /* Asegura que ocupen todo el ancho */
  }

  .section .image {
    margin-bottom: 20px; /* Añade espacio debajo de la imagen */
  }

  .section .text {
    font-size: 1em; /* Ajusta el tamaño del texto para pantallas pequeñas */
    padding: 10px; /* Reduce el padding */
  }

  .section h2 {
    font-size: 1.2em; /* Ajusta el tamaño del subtítulo para pantallas pequeñas */
  }

  .section p {
    font-size: 0.9em; /* Ajusta el tamaño del párrafo para pantallas pequeñas */
  }

  .section .image {
    padding: 10px; /* Reduce el padding */
  }

  .section .image img {
    width: 90%; /* Ajusta el tamaño de la imagen para pantallas pequeñas */
  }

  .section:nth-child(even) .text {
    order: 1; /* Coloca el texto primero en secciones pares */
  }

  .section:nth-child(even) .image {
    order: 2; /* Coloca la imagen después del texto en secciones pares */
  }
}
