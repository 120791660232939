.voluntariados-container {
  width: 100%;
  padding: 60px;
  box-sizing: border-box;
  margin: 0;
  margin-top: 1cm;
  font-family: 'Times New Roman', Times, serif;
}

.voluntariados-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  gap: 20px;
}

.info-column {
  flex: 1;
}

.info-slider {
  width: 100%;
  padding: 0 20px;
}

.slider-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.info-text {
  width: 100%;
  color: rgb(22, 22, 197);
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
}

.info-text h1 {
  font-size: 1.2em;
  color: rgb(240, 83, 10);
  margin-bottom: 20px;
}

.info-text p {
  font-size: 0.8em;
  padding: 0.3cm;
  color: rgb(10, 10, 10);
  margin-bottom: 0px;
  
}

.info-text ul {
  list-style: disc;
  margin-left: 20px;
  font-size: 0.8em;
}

.voluntariados-form {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 40px;
  gap: 20px;
}

.benefits-text {
  width: 100%;
  padding: 20px;
  font-family: 'Times New Roman', Times, serif;
}

.benefits-title,
.benefits-intro {
  color: rgb(7, 7, 7);
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 10px;
  font-family: 'Times New Roman', Times, serif;
}


.benefits-p {
  margin-top: 0;
  font-family: 'Times New Roman', Times, serif;
  font-size: 1.8em;
  padding: 0.3cm;
  color: rgb(0, 0, 0);
  margin-bottom: 0px;
}

.benefits-intro {
  margin-top: 0;

}

.benefits-text ul {
  list-style: none;
  padding: 0;
 
}

.benefits-text li {
  margin-bottom: 10px;
  
  font-size: 1.2em;
}

.form-container {
  width: 100%;
  max-width: 600px;
  padding: 20px;
}

.voluntariados-form h2 {
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
}

.voluntariados-form .form-group {
  margin-bottom: 15px;
  width: 100%;
}

.voluntariados-form .form-group label {
  display: block;
  font-size: 1.2em;
  margin-bottom: 5px;
}

.voluntariados-form .form-group input,
.voluntariados-form .form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  box-sizing: border-box;
}

.voluntariados-form button {
  background-color: #87CEEB;
  color: white;
  font-size: 1.2em;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.voluntariados-form button:hover {
  background-color: #0099cc;
}

@media (max-width: 768px) {
  .voluntariados-info {
    flex-direction: column;
    gap: 20px;
  
  }

  .voluntariados-form {
    flex-direction: column;
    gap: 20px;
  }

  .info-text h1, .benefits-title, .voluntariados-form h2 {
    font-size: 1.5em;
  }

  .info-text p, .benefits-text li {
    font-size: 1em;
    
  }

  .benefits-p {
    margin-top: 0;
    font-family: 'Times New Roman', Times, serif;
    font-size: large ;
    padding: 0.3cm;
    color: rgba(0, 0, 0, 0.923);
    margin-bottom: 0px;
  }

  .info-slider, .form-container {
    padding: 0;
  }
}
