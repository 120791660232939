@import url('https://fonts.googleapis.com/css2?family=Times+New+Roman:wght@400;700&display=swap');

.donaciones-container {
  width: 100%;
  padding: 60px;
  box-sizing: border-box;
  margin: 0;
  font-family: 'Times New Roman', Times, serif;
}

.donaciones-header {
  text-align: center;
  font-weight: bold;
  font-size: 1.5em; /* Aumentar el tamaño de la letra */
  margin-bottom: 25px;
}

.donaciones-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.donaciones-image {
  flex: 1;
  padding-right: 20px;
}

.donaciones-full-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.donaciones-info {
  flex: 1;
  color: rgb(22, 22, 197);
  font-size: 1.2em; /* Ajustar el tamaño de la letra */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.donaciones-info h1 {
  font-size: 1.5em;
  color: rgb(240, 83, 10);
  margin-bottom: 20px;
}

.donaciones-info p {
  font-size: 1.2em; /* Ajustar el tamaño de la letra */
  padding: 0.3cm;
  color: rgb(10, 10, 10);
  margin-bottom: 20px; /* Añadir margen entre párrafos */
  width: 100%; /* Asegura que el texto ocupe todo el espacio de la columna */
  box-sizing: border-box;
}

.donaciones-main-responsive {
  display: none; /* Oculta la sección en pantallas grandes */
}

@media (max-width: 768px) {
  .donaciones-header {
    display: none;
  }

  .donaciones-main {
    display: none;
  }

  .donaciones-main-responsive {
    display: flex; /* Muestra la sección en pantallas pequeñas */
    flex-direction: column;
    align-items: center;
    text-align: justify;
    font-family: 'Times New Roman', Times, serif;
    width: 100%;
    margin-top: 1cm;
  }

  .donaciones-main-responsive .donaciones-image {
    padding-top: 20px;
  }

  .donaciones-main-responsive p {
    width: 100%; /* Asegura que el texto ocupe todo el ancho de la página */
    margin-bottom: 0; /* Elimina el margen entre párrafos */
  }
}
