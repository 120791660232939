.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background: white;
  height: 75px;
  position: fixed;
  top: 0;
  width: 98%;
  z-index: 2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Times New Roman', Times, serif;
}

.header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #87CEEB;
  clip-path: polygon(0 100%, 100% 0, 100% 100%, 0 100%);
  font-family: 'Times New Roman', Times, serif;
  z-index: 0;
}

.logo-container {
  display: flex;
  align-items: center;
  z-index: 1;
  font-family: 'Times New Roman', Times, serif;
}

.logo {
  height: 50px;
  width: auto;
  margin-right: 10px;
}

.header h1 {
  font-size: 1.5em;
  margin: 0;
  color: black;
  z-index: 1;
  font-family: 'Times New Roman', Times, serif;
}

.menu {
  z-index: 1;
}

.menu ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.menu li {
  margin-left: 20px;
}

.menu a {
  color: black;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2em;
  z-index: 1;
}

.menu a:hover {
  text-decoration: underline;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 2;
  padding: 5px;
}

.menu-icon {
  width: 30px;
  height: 30px;
  
}

@media (max-width: 768px) {
  .menu ul {
    display: none;
    flex-direction: column;
    width: 100%;
    text-align: center;
    background: rgb(248, 244, 244);
    position: absolute;
    top: 75px;
    left: 0;
    box-shadow: 0 2px 4px rgb(240, 237, 237);
  }

  .menu ul.show {
    display: flex;
  }

  .menu li {
    margin: 10px 0;
  }

  .menu-toggle {
    display: block;
  }
}
