.form-container {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  margin-top: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  transition: height 0.3s ease;
  font-family: 'Times New Roman', Times, serif;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Times New Roman', Times, serif;
}

.section {
  margin-bottom: 20px;
  width: 100%;
}

.section h2 {
  cursor: pointer;
  margin: 0;
  background-color: #007BFF;
  padding: 10px;
  font-style: italic;
  color: white;
  width: 100%;
  box-sizing: border-box;
  font-family: 'Times New Roman', Times, serif;
}

.section-content {
  display: none;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
  background: white;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Times New Roman', Times, serif;
}

.section.active .section-content {
  display: flex;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

label {
  margin-bottom: 5px;
}

input[type="text"],
input[type="file"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 30px;
  margin-inline: 50px;
}

button:hover {
  background-color: #45a049;
}

.error {
  color: red;
  font-size: 14px;
}

/* Responsive rules */
@media (max-width: 768px) {
  .form-container {
    padding: 0 10px;
  }

  .section-content {
    display: flex !important; /* Asegura que las secciones abiertas permanezcan abiertas */
  }
}
