.footer {
  background-color: #87CEEB;
  color: #fff;
  text-align: center;
  padding: 10px ;
  font-family: 'Times New Roman', Times, serif;
}

.container {
  width: 80%;
  margin: 0 auto;
}

.footer h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
font-family: 'Times New Roman', Times, serif;}

.social-media {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.social-media-item {
  display: flex;
  align-items: center;
  width: 33%;
  justify-content: center;
  text-align: center;
}

.social-media-item img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.social-media-item span {
  display: inline-block;
  vertical-align: super;
  font-family: 'Times New Roman', Times, serif;
}

.footer p {
  margin-top: 10px;
  font-size: larger ;
  font-family: 'Times New Roman', Times, serif;

}

@media (max-width: 768px) {
  .social-media {
    flex-direction: column;
  }

  .footer p {
    margin-top: 0px;
    font-size: small ;
    font-family: 'Times New Roman', Times, serif;
  
  }

  .social-media-item {
    width: 100%;
    margin-bottom: 10px;
  }
}
